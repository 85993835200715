@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

body:hover {
  cursor: pointer;
}

